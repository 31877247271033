<template>
  <div class="merchant"></div>
</template>

<script>
import VConsole from "vconsole";
new VConsole();
export default {
  name: "Merchant",
  beforeCreate() {
    if(window.__wxjs_environment === "miniprogram") {
      wx.miniProgram.reLaunch({ url: `/packages/merchant/pages/status/index?status=opened` });
    } else {
      /** flutter */
      window.flutter_inappwebview.callHandler('exit');
    }
  }
};
</script>

<style lang="scss" scoped>
 p {
   font-size: 20px;
   text-align: center;
 }
</style>
